@use '../../../variables';

.rpsGameTotalPointsValueLarge {
  font-size: 28px;
  height: 42px;
}

.rpsGameTotalPointsValueNormal {
  font-size: 22px;
  height: 42px;
  display: flex;
  align-items: center;
}

.rpsGameTotalPointsValueActive {
  color: variables.$textColor;
}

.rpsGameTotalPointsValueDisabled {
  color: variables.$textColor2;
}
