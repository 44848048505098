@use '../../variables';

.homepageInfoButtonsWrapper {
  width: 100%;
  display: flex;
  gap: 10px;

  button {
    flex: 1;
    padding: 5px;
    border: 2px solid variables.$textColor2;
    border-radius: 5px;
    background-color: transparent;
    color: variables.$textColor2;
    font-family: variables.$font;
    letter-spacing: 1px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      color: variables.$textColor;
      border-color: variables.$textColor;
    }

    &:active {
      color: variables.$textColor;
      color: variables.$textColor;
    }
  }
}
