@use '../../../variables';

.lobbyPaginationContainer {
  position: absolute;
  bottom: 0;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  margin-bottom: 10px;

  .lobbyPaginationButton {
    border: none;
    background-color: variables.$cardBackground2;
    border: 2px solid variables.$buttonColorDisabled;
    margin: 0px 2px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    font-family: variables.$font;
    font-size: 12px;
    color: variables.$textColor2;
    cursor: pointer;
    &:hover {
      border: 2px solid variables.$textColor2;
    }
  }

  .lobbyPaginationButtonActive {
    cursor: auto;
    border: 2px solid variables.$textColor2;
  }

  .lobbyPaginationButtonDisabled {
    border: 2px solid variables.$buttonColorDisabled2;
    cursor: auto;
    &:hover {
      border: 2px solid variables.$buttonColorDisabled2;
    }
  }

  .lobbyPaginationBackIcon {
    padding: 5px;
  }
}
