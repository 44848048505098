@use '../../../variables';

.eventsLogContainer {
  width: calc(100% - 10px);
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: left;
  background-color: #000;
  font-family: variables.$font2;
  font-size: 14px;
  border-radius: 5px;
  padding: 0px 5px;
}

.eventsLogList {
  list-style-type: none;
}

.eventsLogTime {
  color: variables.$textColor2;
}

.eventsLogText {
  color: variables.$textColor;
}

.eventsLogAlternate {
  color: variables.$buttonColorDisabled;
}

.eventsLogTextDisabled {
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-family: variables.$font2;
  font-size: 14px;
  border-radius: 5px;
  color: variables.$buttonColorDisabled;
}
