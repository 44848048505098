@use '../../variables';

.navbarWalletButtonWrapper {
  position: relative;
  width: 155px;
  margin-right: 5px;
  height: 28px;
}

.navbarWalletButtonContainer {
  border: none;
  border-radius: 10px;
  background-color: variables.$cardBackground;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 155px;
}

.navbarWalletButton {
  width: calc(100% - 10px);
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.navbarWalletButtonIconWrapper {
  height: 28px;
  width: calc(100% - 10px);
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    span {
      color: variables.$textColor;
    }
  }

  &:active {
    span {
      color: variables.$textColor;
    }
  }

  span {
    color: variables.$textColor2;
  }
}

.navbarWalletButtonSearchWrapper {
  width: calc(100% - 10px);
  background-color: variables.$cardBackground2;
  padding: 8px 5px 5px;

  .navbarWalletButtonSearchContainer {
    width: calc(100% - 14px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: variables.$cardBackground2;
    border: 2px solid variables.$buttonColorDisabled;
    border-radius: 8px;
    padding: 2px 5px;
    transition: border 0.5s ease-in-out;

    .navbarWalletButtonSearchIcon {
      width: 14px;
      height: 14px;
      margin-right: 7px;
      filter: brightness(0.7);
      transition: filter 0.5s ease-in-out;
    }

    &:hover {
      border-color: variables.$textColor2;

      .navbarWalletButtonSearchIcon {
        filter: brightness(1);
      }
    }

    &:focus-within {
      border-color: variables.$textColor2;

      .navbarWalletButtonSearchIcon {
        filter: brightness(1);
      }
    }

    input {
      width: 100%;
      border: none;
      border-radius: 5px;
      background-color: variables.$cardBackground2;
      color: variables.$textColor;
      font-family: variables.$font;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      outline: none;

      &::placeholder {
        color: variables.$textColor2;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.navbarWalletButtonList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 10px 10px;
  background-color: variables.$cardBackground2;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: variables.$buttonColorDisabled;
    border-right: 6px variables.$cardBackground2 solid;
  }
}

.navbarWalletButtonIcon {
  padding: 1px;
  margin: 5px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: variables.$textColor;
  border-radius: 50%;
}
