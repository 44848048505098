$font: 'Poppins', sans-serif;
$font2: 'Inconsolata', monospace;

$textColor: #f8f8f8;
$textColor2: #d3d3d3;

$backgroundGradient: #5c4380;
$backgroundGradient2: #090c31;
$backgroundGradient3: #000000;
$cardBackground: #21202c;
$cardBackground2: #13131a;
$popupBackground: #121212;

$buttonColor: #dabd1b;
$buttonColorHover: #ffde26;
$buttonColor2: #8a75a7;
$buttonColor2Hover: #bc9ce9;
$buttonColor3: #1ab2ff;
$buttonColor3Hover: #66ccff;
$buttonColor4: #44cc00;
$buttonColor4Hover: #55ff00;
$buttonColor5: #d6291d;
$buttonColor5Hover: #8d1b13;
$buttonColor6: #35661c;
$buttonColor6Hover: #53a02c;

$buttonColorDisabled: #484848;
$buttonColorDisabled2: #222222;
