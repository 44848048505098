@use '../../../variables';

.buttonOutlined {
  height: 34px;
  border: solid 2px;
  border-radius: 10px;
  padding: 0px 8px 1px 9px;
  background-color: transparent;
  font-family: variables.$font;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin: 10px;
  transition: color 0.5s, border-color 0.5s;
  &:disabled {
    border-color: variables.$buttonColorDisabled;
    color: variables.$buttonColorDisabled;
    cursor: auto;
  }
}

.buttonText {
  padding: 0px 1px;
  border: none;
  background-color: transparent;
  font-family: variables.$font;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.buttonGolden {
  border-color: variables.$buttonColor;
  color: variables.$buttonColor;
  &:hover {
    color: variables.$buttonColorHover;
    border-color: variables.$buttonColorHover;
  }
  &:active {
    color: variables.$buttonColorHover;
    color: variables.$buttonColorHover;
  }
  &:disabled {
    border-color: variables.$buttonColorDisabled;
    color: variables.$buttonColorDisabled;
    cursor: auto;
  }
}

.buttonPurple {
  border-color: variables.$buttonColor2;
  color: variables.$buttonColor2;
  &:hover {
    color: variables.$buttonColor2Hover;
    border-color: variables.$buttonColor2Hover;
  }
  &:active {
    color: variables.$buttonColor2Hover;
    border-color: variables.$buttonColor2Hover;
  }
  &:disabled {
    border-color: variables.$buttonColorDisabled;
    color: variables.$buttonColorDisabled;
    cursor: auto;
  }
}

.buttonBlue {
  border-color: variables.$buttonColor3;
  color: variables.$buttonColor3;
  &:hover {
    color: variables.$buttonColor3Hover;
    border-color: variables.$buttonColor3Hover;
  }
  &:active {
    color: variables.$buttonColor3Hover;
    border-color: variables.$buttonColor3Hover;
  }
  &:disabled {
    border-color: variables.$buttonColorDisabled;
    color: variables.$buttonColorDisabled;
    cursor: auto;
  }
}

.buttonGreen {
  border-color: variables.$buttonColor4;
  color: variables.$buttonColor4;
  &:hover {
    color: variables.$buttonColor4Hover;
    border-color: variables.$buttonColor4Hover;
  }
  &:active {
    color: variables.$buttonColor4Hover;
    border-color: variables.$buttonColor4Hover;
  }
  &:disabled {
    border-color: variables.$buttonColorDisabled;
    color: variables.$buttonColorDisabled;
    cursor: auto;
  }
}
