@use '../../variables';

.dbetGameDiceDropdownContainer {
  border: 3px solid variables.$buttonColorDisabled;
  border-radius: 12px;
  background-color: variables.$cardBackground2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: calc(50% - 31px);
  z-index: 2;
}

.dbetGameDiceDropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dbetGameDiceDropdownIcon {
  width: 50px;
  cursor: pointer;
  margin: 0px 3px -7px;
}

.dbetGameDiceDropdownList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 3px variables.$buttonColorDisabled;
  border-radius: 0 0 10px 10px;
  background-color: variables.$cardBackground2;
}
