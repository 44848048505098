@use '../../variables';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;

  .navbarRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .navbarLoginButton {
      height: 28px;
      border: solid 2px;
      border-color: variables.$buttonColor;
      border-radius: 10px;
      padding: 1px 8px 2px 9px;
      margin: 5px 10px;
      color: variables.$buttonColor;
      background-color: transparent;
      font-family: variables.$font;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: variables.$buttonColorHover;
        border-color: variables.$buttonColorHover;
      }
      &:active {
        color: variables.$buttonColorHover;
        color: variables.$buttonColorHover;
      }
    }

    .navbarWalletMenuButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
