@use '../../../variables';

.djackLobbyJoinInfoLoader {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 40px auto 30px;
}

.djackLobbyJoinListNoAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;

  span {
    text-align: center;
    font-size: 13px;
    padding: 10px;
    color: variables.$textColor2;
  }
}
