@use '../../../variables';

.countdownTextWrapper {
  text-align: center;
  font-size: 18px;
  color: variables.$textColor2;
}

.countdownProgressContainer {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;

  .countdownProgressLeft {
    margin: 0 auto;
    width: 50%;
    background: variables.$textColor2;
    float: left;

    .countdownProgressBar {
      height: 2px;
      transition: 1s linear;
      transition-property: width, background-color;
      width: 0%;
      background-color: variables.$cardBackground2;
    }
  }

  .countdownProgressRight {
    margin: 0 auto;
    width: 50%;
    background: variables.$cardBackground2;
    float: right;

    .countdownProgressBar {
      height: 2px;
      transition: 1s linear;
      transition-property: width, background-color;
      width: 100%;
      background-color: variables.$textColor2;
    }
  }
}
