.dbetGameNavigateIconContainer {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 80px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.dbetGameNavigateIconContainerLeft {
  left: 0;
  border-radius: 0px 10px 10px 0px;
}

.dbetGameNavigateIconContainerRight {
  right: 0;
  border-radius: 10px 0px 0px 10px;
}

.dbetGameNavigateIcon {
  position: absolute;
  width: 48px;
  height: 48px;
  top: calc(50% - 5px);
  transform: translateY(-50%);
}

.dbetGameNavigateText {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 10px;
  color: #fff;
  text-align: center;
}
