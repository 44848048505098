@use '../../../variables';

.tncLobbyAccordionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .tncLobbyAccordionTitle {
    margin-top: 2px;
  }

  .tncLobbyCollapseButton {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      padding-top: 4px;
      width: 18px;
      height: 19px;
    }
  }

  .tncLobbyAccordionContainer {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;

    .tncLobbyAccordion {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 2px;
      font-size: 14px;
      width: 86px;

      span {
        color: variables.$textColor2;
        font-size: 10px;
      }

      .tncLobbyAccordionInputWrapper {
        flex-direction: row;
        padding-bottom: 2px;

        input {
          font-family: variables.$font;
          font-size: 18px;
          border: none;
          width: 40px;
          background-color: transparent;
          color: variables.$textColor;
          text-align: center;
          &:focus {
            outline: none;
          }
          &::-webkit-inner-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }

        span {
          padding-left: 2px;
          font-size: 12px;
          color: variables.$textColor2;
        }
      }
    }
  }

  .tncLobbyCreateButton {
    height: 60px;
    width: 100%;
    z-index: 1;
    margin: 5px 0px 0px;
    border-radius: 5px;
    border: none;
    &::before {
      content: '';
      background-image: linear-gradient(
        to right,
        variables.$buttonColor6Hover 0%,
        variables.$buttonColor6 50%,
        variables.$buttonColor6Hover 100%
      );
      background-size: 200% auto;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 1;
      transition: background-position 0.5s, opacity 0.5s;
      z-index: -1;
      border-radius: 5px;
    }
    color: variables.$textColor;
    font-family: variables.$font;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 10px variables.$backgroundGradient;
    background-color: variables.$buttonColorDisabled2;
    position: relative;
    &:hover {
      &::before {
        background-position: right center;
      }
    }
    &:active {
      &::before {
        background-position: right center;
      }
    }
    &:disabled {
      cursor: auto;
      color: variables.$textColor2;
      box-shadow: none;
      &::before {
        opacity: 0;
      }
    }
  }
}
