@use '../../../variables';

.tncGameScoreWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 50px;
  .tncGameScoreText {
    color: variables.$textColor2;
    font-size: 8px;
  }
  .tncGameScoreValue {
    font-size: 24px;
  }
}
