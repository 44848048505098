.dbetGameCurrentRatesRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 5px;

  .dbetGameRateWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 96px;
  }
}
