@use '../../variables';

.navbarHomeButton {
  border: none;
  background-color: transparent;
  height: 28px;
  margin: 5px 0px 5px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .navbarHomeIcon {
    width: 25px;
    height: 25px;
    margin: 0px 5px;
    filter: brightness(1);
    transition: filter 0.5s ease-in-out;
  }

  span {
    font-family: variables.$font;
    font-size: 16px;
    letter-spacing: 2px;
    color: variables.$textColor;
  }

  &:hover {
    .navbarHomeIcon {
      filter: brightness(1.2);
    }
    span {
      color: white;
    }
  }
  &:active {
    .navbarHomeIcon {
      filter: brightness(1.2);
    }
    span {
      color: white;
    }
  }
}
