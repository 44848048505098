@use './variables';
@use 'views';
@use 'components';

* {
  margin: 0;
  padding: 0;
}

html {
  color-scheme: dark;
}

#root {
  background: linear-gradient(
    135deg,
    variables.$backgroundGradient,
    variables.$backgroundGradient2,
    variables.$backgroundGradient3
  );
  background-attachment: fixed;
  min-height: 100vh;
  font-family: variables.$font;
  color: variables.$textColor;
}

.container {
  position: relative;
  max-width: 420px;
  margin: 0 auto;
  padding: 10px 0px;
}

.card {
  margin: 10px 10px 0px;
  background-color: variables.$cardBackground;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  .cardTitle {
    margin: 0px 5px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .innerCard {
    position: relative;
    margin: 10px 0px 0px;
    background-color: variables.$cardBackground2;
    padding: 5px 10px 10px;
    border-radius: 10px;
    .innerCardTitle {
      margin: 0px 0px 5px;
    }
    .innerCardTitleWithButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    width: 100%;
    max-width: 376px;
    background-color: variables.$popupBackground;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid variables.$buttonColorDisabled;
  }
}
