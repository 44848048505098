.tncGameBallStatsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  padding: 0px 5px;
  .tncGameBallStatsDivider {
    list-style-type: none;
    min-width: 65px;
  }
  .tncGameBallStatsListItem {
    display: flex;
    align-items: center;
  }
  .tncGameBallStatsIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
