@use '../../variables';

.homepageSliderTitle {
  width: 100%;
  text-align: center;
  font-family: variables.$font;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
}

.homepageSliderWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 2px;
  height: 250px;
  overflow: hidden;
  background-color: variables.$cardBackground2;
  border-radius: 10px;
  margin-top: 10px;

  .homepageSliderNavigateIconContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 30px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    cursor: pointer;
  }

  .homepageSliderNavigateIconContainerLeft {
    left: 0px;
    border-radius: 0px 10px 10px 0px;
  }

  .homepageSliderNavigateIconContainerRight {
    right: 0;
    border-radius: 10px 0px 0px 10px;
  }

  .homepageSliderNavigateIcon {
    width: 24px;
    height: 24px;
  }

  .homepageSliderContainer {
    height: 100%;
    width: 300%;
    display: flex;
    transition: all ease-in-out 0.5s;

    .homepageSliderSection {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      .homepageSliderSectionBackground {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 240px;
        height: 240px;
        z-index: -1;
      }

      .homepageSliderSectionText {
        flex: 1;
        padding: 20px 20px 20px;
        text-align: center;
        z-index: 1;

        h1 {
          font-family: variables.$font;
          font-size: 22px;
          font-weight: 400;
          letter-spacing: 3px;
          margin-top: 20px;
          color: variables.$textColor;
        }

        p {
          margin: 25px 20px 35px;
          font-family: variables.$font;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 1px;
          color: variables.$textColor2;
        }

        button {
          padding: 5px 10px;
          border: 2px solid variables.$buttonColor3;
          border-radius: 5px;
          background-color: transparent;
          color: variables.$buttonColor3;
          font-family: variables.$font;
          letter-spacing: 1px;
          font-size: 16px;
          cursor: pointer;

          &:hover {
            color: variables.$buttonColor3Hover;
            border-color: variables.$buttonColor3Hover;
          }

          &:active {
            color: variables.$buttonColor3Hover;
            color: variables.$buttonColor3Hover;
          }

          &:disabled {
            color: variables.$buttonColorDisabled;
            border-color: variables.$buttonColorDisabled;
            cursor: auto;
          }
        }
      }
    }
  }
  .homepageSliderDisplayer {
    height: 2px;
    width: 166.6666%;
    display: flex;
    transition: all ease-in-out 0.5s;

    .homepageSliderDisplayerColumn {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: transparent;
    }

    .homepageSliderDisplayerColumnActive {
      background-color: variables.$textColor2;
      border-radius: 5px;
    }
  }
}
