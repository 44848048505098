@use '../../variables';

.navbarBottomSheetLineWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 27px;
  margin-bottom: -10px;

  .navbarBottomSheetLine {
    background-color: #f5f5f5;
    width: 120px;
    height: 3px;
    border-radius: 2px;
  }
}

.navbarBottomSheetCard {
  margin: 10px;
  padding: 10px;
  background-color: variables.$popupBackground;
  border-radius: 10px;
  font-size: 14px;
}

.navbarBottomSheetCenter {
  display: flex;
  justify-content: center;
}

.navbarBottomSheetLogoutButton {
  height: 28px;
  border: solid 2px;
  border-color: variables.$buttonColor5;
  border-radius: 10px;
  padding: 1px 8px 2px 9px;
  color: variables.$buttonColor5;
  background-color: transparent;
  font-family: variables.$font;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: variables.$buttonColor5Hover;
    border-color: variables.$buttonColor5Hover;
  }
  &:active {
    color: variables.$buttonColor5Hover;
    border-color: variables.$buttonColor5Hover;
  }
}
