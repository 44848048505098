@use '../../../variables';

.gameInfoIcon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.gameInfoButton {
  position: absolute;
  top: 9px;
  right: 9px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  .gameInfoCollapseIcon {
    padding-top: 4px;
    width: 18px;
    height: 18px;
    filter: brightness(1);
  }

  &:hover {
    .gameInfoCollapseIcon {
      filter: brightness(1.2);
    }
  }
  &:active {
    .gameInfoCollapseIcon {
      filter: brightness(1.2);
    }
  }
}

.gameInfoCardBody {
  font-size: 13px;
  padding-left: 15px;
  font-weight: 300;
  a {
    color: variables.$buttonColor3;
    text-decoration: none;
    &:hover {
      color: variables.$buttonColor3Hover;
    }
    &:active {
      text-decoration: underline;
    }
  }
  li {
    height: 39px;
    line-height: 39px;
    vertical-align: middle;

    p {
      line-height: 1.5;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
