@use '../variables';

.tncGameScoresWrapper {
  max-width: 300px;
  display: flex;
  margin: 10px auto 5px;
  justify-content: space-between;
}

.tncGameSchemaWrapper {
  display: flex;
  justify-content: center;
  min-width: 270px;
  margin: 0px auto;
}

.tncGamePlayersWrapper {
  max-width: 300px;
  display: flex;
  margin: 5px auto;
  justify-content: space-between;
}
