@use '../../../variables';

.prizeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px 0px 0px;
}

.prizeText {
  color: variables.$textColor2;
  font-size: 10px;
  margin-bottom: 0;
}

.prizeValue {
  font-size: 20px;
  text-align: center;
}

.prizeUnit {
  font-size: 14px;
}
