@use '../../variables';

.homepageInfoCardWrapper {
  display: flex;
  flex-direction: column;

  .homepageInfoCardContainer {
    background-color: variables.$cardBackground2;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      font-family: variables.$font;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    p {
      font-family: variables.$font;
      font-size: 13px;
      font-weight: 300;
      color: variables.$textColor2;
    }
  }
}
