@use '../../../variables';

.djackLobbyJoinContainer {
  margin: 5px 0px 0px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  border: 2px solid variables.$buttonColorDisabled;
  border-radius: 6px;

  &:hover {
    border: 2px solid variables.$textColor2;
  }

  &:active {
    border: 2px solid variables.$textColor2;
  }

  .djackLobbyJoinInfoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 36px;
    cursor: pointer;

    .djackLobbyJoinInfoText {
      text-align: center;
      color: variables.$textColor2;
      font-size: 10px;
      margin-bottom: 0;
      min-width: 50px;
    }

    .djackLobbyJoinInfoValueContainer {
      flex-direction: row;
    }

    .djackLobbyJoinInfoValue {
      font-size: 20px;
    }
  }

  .djackLobbyJoinButton {
    width: 36px;
    height: 50px;
    padding-top: 2px;
    background-color: transparent;
    border: none;
    font-family: variables.$font;
    font-size: 32px;
    font-weight: 300;
    text-align: center;
    color: variables.$buttonColor;
    cursor: pointer;
    transition: color 0.5s;
    &:hover {
      color: variables.$buttonColorHover;
    }
    &:active {
      color: variables.$buttonColorHover;
    }
    &:disabled {
      cursor: auto;
      color: variables.$buttonColorDisabled;
    }
  }
}
