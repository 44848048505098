@use '../../../variables';

.djackGameDiceImgWrapper {
  width: 64px;
  height: 64px;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
  }
}
