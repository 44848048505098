.dbetGameDiceRollContainer {
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  width: 120px;
  height: 120px;

  .dbetGameDiceRollWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
