@use '../../variables';

.homepageContactWrapper {
  display: flex;
  flex-direction: column;

  .homepageContactContainer {
    background-color: variables.$cardBackground2;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    p {
      font-family: variables.$font;
      font-size: 13px;
      font-weight: 300;
      color: variables.$textColor;
      text-align: center;
    }

    button {
      padding: 5px 10px;
      border: 2px solid variables.$buttonColor3;
      border-radius: 5px;
      background-color: transparent;
      color: variables.$buttonColor3;
      font-family: variables.$font;
      letter-spacing: 1px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: variables.$buttonColor3Hover;
        border-color: variables.$buttonColor3Hover;
      }

      &:active {
        color: variables.$buttonColor3Hover;
        color: variables.$buttonColor3Hover;
      }
    }
  }
}
