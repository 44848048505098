@use '../../../variables';

.tncGameUIButtonLoader {
  position: relative;
  width: 50px;
  height: 24px;
  margin: 20px auto 15px;
}

.tncGameButtonWrapper {
  display: flex;
  justify-content: space-evenly;
}

.tncGameForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2px;
  font-size: 13px;
  width: 86px;

  .tncGameFormLabel {
    color: variables.$textColor2;
    font-size: 10px;
  }
  .tncGameFormInputWrapper {
    flex-direction: row;
  }
  .tncGameFormInput {
    font-family: variables.$font;
    font-size: 16px;
    border: none;
    padding: 0px 0px 0px 5px;
    width: 46px;
    background-color: transparent;
    color: variables.$textColor;

    &:focus {
      outline: none;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  .tncGameFormBallSpeed {
    font-size: 16px;
  }
  .tncGameFormUnit {
    padding-left: 5px;
  }
}
