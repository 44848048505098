@use '../../../variables';

.rpsGameTotalPointsRecentWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 46px;
  height: 58px;

  .rpsGameUIBottomIcon {
    width: 28px;
    height: 28px;
  }

  .rpsGameUIBottomText {
    font-size: 10px;
    padding: 3px 0px 0px;
    text-align: center;
  }
}
