@use '../../variables';

.navbarMenuButton {
  height: 28px;
  width: 30px;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  padding: 3px 8px;
  background-color: #202020;
  cursor: pointer;

  &:hover {
    .navbarMenuButtonIcon {
      filter: brightness(1.2);
    }
  }

  &:active {
    .navbarMenuButtonIcon {
      filter: brightness(1.2);
    }
  }

  .navbarMenuButtonIcon {
    width: 16px;
    height: 16px;
    transition: filter 0.5s ease-in-out;
  }
}
