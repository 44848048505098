.dbetGameRollAreaWrapper {
  position: relative;
  padding: 0px 10px;
}

.dbetGameBlockStatsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.dbetGameCurrentRatesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
