@use '../../variables';

.loadingDotsWrapper {
  width: 32px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  ul {
    list-style-type: none;
    margin: 5px 0;
    display: inline-block;
  }

  .loadingDots li {
    background: variables.$buttonColorDisabled;
    border-radius: 100%;
    width: 5px;
    height: 5px;
    float: left;
    margin: 0 2px;
    animation: float 1.8s infinite;
    transform-origin: center;
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes float {
    0%,
    100% {
      transform: translateY(4px);
    }
    50% {
      transform: translateY(-4px);
    }
  }
}
