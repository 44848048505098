@use '../../../variables';

.addressCopyNotificationContainer {
  position: fixed;
  top: 15px;
  left: calc(50% - 70px);
  width: 140px;
  height: 28px;
  background-color: variables.$cardBackground;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  span {
    font-size: 12px;
    color: variables.$textColor;
    text-align: center;
  }
}
