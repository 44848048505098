@use '../../variables';

.dbetGameAccordionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dbetGameAccordionTitle {
  margin-top: 2px;
  text-align: center;
}

.dbetGameCollapseButton {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  img {
    padding-top: 4px;
    width: 18px;
    height: 19px;
  }
}

.dbetGameAccordionContainer {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;

  .dbetGameAccordion {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2px;
    font-size: 14px;

    .dbetGameAccordionLabel {
      color: variables.$textColor2;
      font-size: 10px;
    }
    .dbetGameAccordionInputWrapper {
      flex-direction: row;
      padding-bottom: 2px;
    }
    .dbetGameAccordionInput {
      font-family: variables.$font;
      font-size: 18px;
      border: none;
      padding: 0px 0px 0px 26px;
      width: 50px;
      background-color: transparent;
      color: variables.$textColor;
      text-align: center;

      &:focus {
        outline: none;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    .dbetGameAccordionUnit {
      padding-left: 2px;
      font-size: 12px;
      color: variables.$textColor2;
    }
  }
}

.dbetGameUIButtonContainer {
  margin: -5px 0px -15px;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dbetGameDiceDropdownWrapper {
  margin-top: 5px;
  width: 100%;
  height: 62px;
  position: relative;
}
