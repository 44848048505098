@use '../../../variables';

.oneGameUIButtonWrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .oneGameUITopContainer {
    position: absolute;
    top: calc(50% - 42px);
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      font-size: 20px;
      color: variables.$textColor;
      width: 24px;
      text-align: center;
    }

    .oneGameUIHoldValueButton {
      padding: 0px 2px;
      width: 20px;
      text-align: center;
      border: none;
      background-color: transparent;
      font-family: variables.$font;
      font-size: 24px;
      cursor: pointer;
      color: variables.$buttonColor3;
      &:hover {
        color: variables.$buttonColor3Hover;
      }
      &:active {
        color: variables.$buttonColor3Hover;
      }
      &:disabled {
        color: variables.$buttonColorDisabled;
        cursor: auto;
      }
    }
  }

  .oneGameUICenterContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .oneGameUIBottomContainer {
    position: absolute;
    top: calc(50% + 50px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
