@use '../../../variables';

.djackGameTotalPointsScreenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 5px;

  .djackGameSinglePoint {
    height: 6px;
    width: 5px;
    border-radius: 100%;
    margin-top: 5px;
    border: none;
    transition: background-color 1s;
  }

  .djackGameSinglePointOutlined {
    border: 1px solid variables.$buttonColorDisabled;
    box-shadow: 0 0 5px variables.$backgroundGradient;
  }

  .djackGameSinglePointTransparent {
    background-color: transparent;
  }

  .djackGameSinglePointWin {
    background-color: variables.$buttonColor4;
  }

  .djackGameSinglePointDraw {
    background-color: variables.$buttonColor;
  }

  .djackGameSinglePointLose {
    background-color: variables.$buttonColor5;
  }
}
