@use '../../../variables';

.djackGameTotalPointsValueLarge {
  font-size: 28px;
}

.djackGameTotalPointsValueNormal {
  font-size: 22px;
}

.djackGameTotalPointsValueActive {
  color: variables.$textColor;
}

.djackGameTotalPointsValueDisabled {
  color: variables.$textColor2;
}
