@use '../../../variables';

.rpsGameUIButtonWrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .rpsGameUITopContainer {
    position: absolute;
    top: calc(50% - 46px);
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .rpsGameUICenterContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .rpsGameUIBottomContainer {
    position: absolute;
    top: calc(50% + 54px);
    left: 50%;
    transform: translate(-50%, -50%);

    .rpsGameUIBottomWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;

      .rpsGameUIBottomIcon {
        width: 28px;
        height: 28px;
      }

      .rpsGameUIBottomText {
        font-family: variables.$font;
        font-size: 10px;
        padding: 3px 0px 0px;
        text-align: center;
      }
    }
  }
}

.rpsGameUIHoldSelectionWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 58px;
    margin: 8px;
    background-color: transparent;
    border: solid 2px;
    border-radius: 10px;
    border-color: variables.$buttonColor3;
    transition: color 0.5s, border-color 0.5s;
    cursor: pointer;

    .rpsGameUIHoldSelectionIcon {
      width: 28px;
      height: 28px;
      padding-top: 4px;
    }

    .rpsGameUIHoldSelectionText {
      font-family: variables.$font;
      font-size: 10px;
      padding: 3px 0px 0px;
      text-align: center;
      color: variables.$buttonColor3;
    }

    &:hover {
      border-color: variables.$buttonColor3Hover;
      .rpsGameUIHoldSelectionText {
        color: variables.$buttonColor3Hover;
      }
    }

    &:active {
      border-color: variables.$buttonColor3Hover;
      .rpsGameUIHoldSelectionText {
        color: variables.$buttonColor3Hover;
      }
    }

    &:disabled {
      border-color: variables.$buttonColorDisabled;
      .rpsGameUIHoldSelectionText {
        color: variables.$buttonColorDisabled;
      }
      cursor: auto;
    }
  }
}
