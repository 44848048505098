@use '../../variables';

.navbarBottomSheetWallet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .navbarBottomSheetWalletHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    img {
      width: 14px;
      height: 14px;
      padding-bottom: 2px;
    }

    div {
      width: 84px;
    }
  }

  .navbarBottomSheetWalletInputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    font-size: 14px;
    width: 100%;

    span {
      color: variables.$textColor2;
      font-size: 10px;
    }

    .navbarBottomSheetWalletInputContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 4px;
      width: 100%;
      padding-bottom: 2px;

      input {
        font-family: variables.$font;
        font-size: 18px;
        border: none;
        width: 100%;
        background-color: transparent;
        color: variables.$textColor;
        text-align: end;
        &:focus {
          outline: none;
        }
        &::-webkit-inner-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }

      span {
        padding-top: 6px;
        font-size: 12px;
        color: variables.$textColor2;
      }
    }
  }

  .navbarBottomSheetWalletActionButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    margin-top: 4px;

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 58px;
      margin: 8px;
      background-color: transparent;
      border: solid 2px;
      border-radius: 10px;
      border-color: variables.$buttonColorDisabled;
      transition: color 0.5s, border-color 0.5s;
      cursor: pointer;

      .navbarBottomSheetWalletActionButtonIcon {
        width: 36px;
        height: 36px;
        margin-bottom: -4px;
      }

      .navbarBottomSheetWalletActionButtonText {
        font-family: variables.$font;
        font-size: 10px;
        padding: 3px 0px 0px;
        text-align: center;
        color: variables.$buttonColorDisabled;
        transition: color 0.5s;
      }

      &:hover {
        border-color: variables.$buttonColor3Hover;
        .navbarBottomSheetWalletActionButtonText {
          color: variables.$buttonColor3Hover;
        }
      }

      &:active {
        border-color: variables.$buttonColor3Hover;
        .navbarBottomSheetWalletActionButtonText {
          color: variables.$buttonColor3Hover;
        }
      }
    }

    .navbarBottomSheetWalletActionButtonActive {
      border-color: variables.$buttonColor3;
      .navbarBottomSheetWalletActionButtonText {
        color: variables.$buttonColor3;
      }
    }
  }

  .navbarBottomSheetWalletSwiperContainer {
    width: 270px;
    height: 40px;
    margin: 12px 0 4px;
    background-color: variables.$buttonColorDisabled2;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 10px;
      color: variables.$textColor2;
    }

    .navbarBottomSheetWalletSwiper {
      width: 40px;
      height: 40px;
      background-color: variables.$textColor2;
      border-radius: 10px;
      position: absolute;
      top: calc(50% - 20px);
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .navbarBottomSheetWalletSwiperIcon {
        width: 80%;
        height: 80%;
      }
    }
  }
}

.navbarBottomSheetWalletSwiperContainerAnimated {
  background: variables.$buttonColorDisabled2;
  background: -webkit-linear-gradient(
    45deg,
    variables.$buttonColorDisabled2 0%,
    variables.$buttonColorDisabled 33%,
    variables.$buttonColorDisabled2 66%,
    variables.$buttonColorDisabled 100%
  );
  background: -moz-linear-gradient(
    45deg,
    variables.$buttonColorDisabled2 0%,
    variables.$buttonColorDisabled 33%,
    variables.$buttonColorDisabled2 66%,
    variables.$buttonColorDisabled 100%
  );
  background: -ms-linear-gradient(
    45deg,
    variables.$buttonColorDisabled2 0%,
    variables.$buttonColorDisabled 33%,
    variables.$buttonColorDisabled2 66%,
    variables.$buttonColorDisabled 100%
  );
  background: -o-linear-gradient(
    45deg,
    variables.$buttonColorDisabled2 0%,
    variables.$buttonColorDisabled 33%,
    variables.$buttonColorDisabled2 66%,
    variables.$buttonColorDisabled 100%
  );
  background: linear-gradient(
    45deg,
    variables.$buttonColorDisabled2 0%,
    variables.$buttonColorDisabled 33%,
    variables.$buttonColorDisabled2 66%,
    variables.$buttonColorDisabled 100%
  );
  background-size: 400% 400%;
  -webkit-animation: navbarBottomSheetWalletSwiperContainerAnimatedBG 2s linear
    infinite;
  -moz-animation: navbarBottomSheetWalletSwiperContainerAnimatedBG 2s linear
    infinite;
  animation: navbarBottomSheetWalletSwiperContainerAnimatedBG 2s linear infinite;
  height: 40px;
}

@-webkit-keyframes navbarBottomSheetWalletSwiperContainerAnimatedBG {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 15% 100%;
  }
}

@-moz-keyframes navbarBottomSheetWalletSwiperContainerAnimatedBG {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 15% 100%;
  }
}

@keyframes navbarBottomSheetWalletSwiperContainerAnimatedBG {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 15% 100%;
  }
}
