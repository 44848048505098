@use '../../variables';

.navbarLanguageDropdownWrapper {
  position: relative;
  width: 30px;
  height: 28px;
  margin-right: 5px;
}

.navbarLanguageDropdownContainer {
  border: none;
  border-radius: 10px;
  background-color: variables.$cardBackground;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.navbarLanguageDropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbarLanguageDropdownIconWrapper {
  height: 28px;
}

.navbarLanguageDropdownList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 10px 10px;
  background-color: variables.$cardBackground2;
}

.navbarLanguageDropdownIcon {
  padding: 4px 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
