@use '../../variables';

.navbarSwitchChainModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 14px;
    color: variables.$textColor;
    margin: 8px 0 16px;
    text-align: center;
  }

  button {
    border: solid 2px;
    border-color: variables.$buttonColor3;
    border-radius: 10px;
    padding: 4px 8px;
    color: variables.$buttonColor3;
    background-color: transparent;
    font-family: variables.$font;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;

    cursor: pointer;
    &:hover {
      color: variables.$buttonColor3Hover;
      border-color: variables.$buttonColor3Hover;
    }
    &:active {
      color: variables.$buttonColor3Hover;
      border-color: variables.$buttonColor3Hover;
    }
  }
}
