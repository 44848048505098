@use '../../../variables';

.oneGameTotalPointsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.oneGameTotalPointsPlayerContainer {
  height: 262px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 4px;

  .oneGameTotalPointsTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 46px;

    .oneGameTotalPointsHeader {
      text-align: center;
      color: variables.$textColor2;
      font-size: 10px;
      margin-bottom: 0;
    }
  }
}
