@use '../../../variables';

.djackGameStandingsJoinButton {
  width: 50px;
  height: 50px;
  padding-top: 2px;
  background-color: transparent;
  border: none;
  font-family: variables.$font;
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  color: variables.$buttonColor;
  cursor: pointer;
  transition: color 0.5s;
  &:hover {
    color: variables.$buttonColorHover;
  }
  &:active {
    color: variables.$buttonColorHover;
  }
  &:disabled {
    cursor: auto;
    color: variables.$buttonColorDisabled;
  }
}
