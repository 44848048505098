@use '../../../variables';

.oneGameTotalPointsScreenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 5px;

  .oneGameSinglePoint {
    height: 6px;
    width: 5px;
    border-radius: 100%;
    margin-top: 5px;
    border: none;
    transition: background-color 1s;
  }

  .oneGameSinglePointOutlined {
    border: 1px solid variables.$buttonColorDisabled;
    box-shadow: 0 0 5px variables.$backgroundGradient;
  }

  .oneGameSinglePointTransparent {
    background-color: transparent;
  }

  .oneGameSinglePointWin {
    background-color: variables.$buttonColor4;
  }

  .oneGameSinglePointDraw {
    background-color: variables.$buttonColor;
  }

  .oneGameSinglePointLose {
    background-color: variables.$buttonColor5;
  }
}
