.djackGameGamingAreaWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.djackGameStandingsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.djackGameStatsWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.djackGameCountdownWrapper {
  position: relative;
  width: calc(100% + 20px);
  margin: -25px 0px -10px -10px;
  border-radius: 10px;
  overflow: hidden;
  height: 35px;
}
