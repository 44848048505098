$spinner-size: 30px;

.spinner {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  > .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $spinner-size;
    height: $spinner-size;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    > svg {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      > circle {
        stroke: #fff;
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
