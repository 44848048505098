@use '../../variables';

.faucetForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
  gap: 2px;
}
