@use '../../../variables';

.oneGameTotalPointsValueLarge {
  font-size: 28px;
  height: 42px;
}

.oneGameTotalPointsValueNormal {
  font-size: 22px;
  height: 42px;
  display: flex;
  align-items: center;
}

.oneGameTotalPointsValueActive {
  color: variables.$textColor;
}

.oneGameTotalPointsValueDisabled {
  color: variables.$textColor2;
}
