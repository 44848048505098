@use '../../../variables';

.djackGameTotalPointsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.djackGameTotalPointsPlayerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-top: 8px;

  .djackGameTotalPointsTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 46px;
    margin-bottom: 15px;

    .djackGameTotalPointsHeader {
      text-align: center;
      color: variables.$textColor2;
      font-size: 10px;
      margin-bottom: 0;
    }
  }
}
