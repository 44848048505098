@use '../../variables';

.homepageTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .homepageTitleHeader {
    font-size: 32px;
    letter-spacing: 4px;
    margin-top: 15px;
    font-weight: 400;
  }

  .homepageTitleDesc {
    font-size: 16px;
    letter-spacing: 7px;
    margin-bottom: 35px;
    font-weight: 300;
    color: variables.$textColor2;
  }

  .homepageTitleSvg {
    width: 280px;
    height: 150px;
    margin-bottom: 45px;
  }
  .homepageTitleInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 81px;
    max-width: 280px;
    margin-bottom: 20px;

    span {
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      letter-spacing: 0.5px;
    }
  }

  .homepageTitleInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 42px;
    max-width: 280px;
    margin-bottom: 35px;

    span {
      font-size: 14px;
      font-weight: 200;
      text-align: center;
      color: variables.$textColor2;
      letter-spacing: 0.5px;
    }
  }

  .bicolympicsTitleWavesWrapper {
    width: 100%;
    height: 50px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      -moz-box-shadow: inset 0 0 15px variables.$cardBackground;
      -webkit-box-shadow: inset 0 0 15px variables.$cardBackground;
      box-shadow: inset 0 0 15px variables.$cardBackground;
    }

    .bicolympicsTitleWaves {
      width: 100%;
      height: 100%;
      border-radius: 5px;

      .parallax > use {
        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
      }
      .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
      }
      .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
      }
      .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
      }
      .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
      }
      @keyframes move-forever {
        0% {
          transform: translate3d(-90px, 0, 0);
        }
        100% {
          transform: translate3d(85px, 0, 0);
        }
      }
      /*Shrinking for mobile*/
      @media (max-width: 768px) {
        .waves {
          height: 40px;
          min-height: 40px;
        }
        .content {
          height: 30vh;
        }
        h1 {
          font-size: 24px;
        }
      }
    }
  }
}
