@use '../../../variables';

.playerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.playerText {
  display: flex;
  justify-content: center;
  color: variables.$textColor2;
  width: 50px;
  font-size: 8px;
}

.playerIcon {
  width: 28px;
  padding: 4px 5px 0px;
}
