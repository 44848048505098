@use '../../../variables';

.oneGameTotalPointsRecentWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 46px;
  height: 58px;

  .oneGameTotalPointsRecentHeader {
    text-align: center;
    color: variables.$textColor2;
    font-size: 10px;
    margin-bottom: 0;
  }
}
