@use '../../../variables';

.rpsGameTotalPointsScreenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 262px;
  margin: 5px 5px;

  .rpsGameSinglePoint {
    height: 6px;
    width: 5px;
    border-radius: 100%;
    margin-top: 5px;
    border: nrps;
    transition: background-color 1s;
  }

  .rpsGameSinglePointOutlined {
    border: 1px solid variables.$buttonColorDisabled;
    box-shadow: 0 0 5px variables.$backgroundGradient;
  }

  .rpsGameSinglePointTransparent {
    background-color: transparent;
  }

  .rpsGameSinglePointWin {
    background-color: variables.$buttonColor4;
  }

  .rpsGameSinglePointDraw {
    background-color: variables.$buttonColor;
  }

  .rpsGameSinglePointLose {
    background-color: variables.$buttonColor5;
  }
}
