.diceOutlinedIcon {
  width: 60px;
  padding: 0px 5px;
  margin-bottom: -5px;
}

.diceOutlinedIconLarge {
  width: 100px;
  padding: 0px 5px;
}
