@use '../../variables';

.homepageGamesListTitleIcon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.homepageGamesListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  .homepageGamesListItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    background-color: variables.$cardBackground2;
    padding: 10px;
    border-radius: 10px;

    .homepageGamesListItemLeft {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding-left: 5px;

      .homepageGamesListItemIconWrapper {
        width: 42px;
        height: 42px;
      }

      .homepageGamesListItemTextWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h4 {
          font-family: variables.$font;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 1px;
        }

        p {
          font-family: variables.$font;
          font-size: 13px;
          font-weight: 300;
          letter-spacing: 1px;
          color: variables.$textColor2;
        }
      }
    }

    .homepageGamesListItemRight {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 5px;
      align-items: center;

      button {
        padding: 5px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        height: 100%;

        img {
          width: 28px;
          height: 28px;
          filter: brightness(1);
        }

        &:hover {
          img {
            filter: brightness(1.2);
          }
        }
        &:active {
          img {
            filter: brightness(1.2);
          }
        }
      }
    }
  }
}
